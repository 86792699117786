<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog
      :title="title"
      :visible="showDialog"
      @close="btnCancel"
      top="3vh"
    >
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="200px"
      >
        <el-form-item label="logo" prop="logoId">
          <el-upload
            class="avatar-uploader"
            action="http://up-z0.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="100%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="标题名称" prop="title">
          <el-input
            v-model="formData.title"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>

        <el-form-item
          label="来源类别"
          prop="originType"
          v-if="!formData.rotationId"
        >
          <el-select
            v-model="formData.originType"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.lebal"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="类别"
          prop="originId"
          v-if="
            (formData.originType == 'ARTICLE' && !formData.rotationId) ||
            (formData.originType == 'PDF' && !formData.rotationId) ||
            (formData.originType == 'ACTIVITY' && !formData.rotationId)
          "
        >
          <el-table
            :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
            :data="tabelList"
            border
            :key="formData.originType"
            style="width: 100%"
            ref="singleTable"
            class="emp_table"
            @select="handleSelectionChange"
            v-if="tabelList"
          >
            <el-table-column type="selection" align="center" width="55" />
            <!-- <el-table-column
          width="55px"
         
          align="center">
          <template slot-scope="scope">
             <el-radio  v-model="radioSelected" :label="scope.row.rotationId"  @selection-change="enter"><span></span></el-radio> 
            
           
          </template>
        </el-table-column>  -->

            <el-table-column
              v-if="formData.originType == 'ARTICLE'"
              align="center"
              prop="logoId"
              label="logo"
              width="200"
            >
              <template slot-scope="scope">
                <div class="permitImg-img">
                  <el-image
                    style="width: 35%"
                    :src="'http://file.innopinenut.com/' + scope.row.logoId"
                    :preview-src-list="[
                      'http://file.innopinenut.com/' + scope.row.logoId
                    ]"
                  />
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="title"
              label="标题"
              :show-overflow-tooltip="true"
            />
            <!-- http://file.innopinenut.com/ -->

            <el-table-column align="center" label="操作" width="251px">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="enter(scope.row)">
                  确定
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            v-if="formData.originType == 'ARTICLE'"
            :total="count"
            :page.sync="qq.page"
            :limit.sync="qq.pageSize"
            @pagination="getArticles"
          />
          <pagination
            v-if="formData.originType == 'PDF'"
            :total="count"
            :page.sync="qq.page"
            :limit.sync="qq.pageSize"
            @pagination="getPDFInfoList"
          />
        </el-form-item>
        <el-form-item
          label="外部链接"
          prop="urlMsg"
          v-if="formData.originType == 'WEBURL'"
        >
          <el-input v-model="formData.urlMsg" style="width: 100%" />
        </el-form-item>

        <el-form-item label="排序" prop="sort" v-if="formData.rotationId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <!-- <el-row slot="footer" type="flex" justify="center">
        <el-col >
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row> -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="btnOK"> 确定 </el-button>
        <el-button size="small" @click="btnCancel">取消</el-button>
      </span>
    </el-dialog>

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加轮播图</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column align="center" prop="logoId" label="logo" width="200">
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://file.innopinenut.com/' + scope.row.logoId"
                :preview-src-list="[
                  'http://file.innopinenut.com/' + scope.row.logoId
                ]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="title"
          label="标题"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          align="center"
          prop="originType"
          label="	来源类别"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.originType | ot }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="sort"
          label="排序"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
            <el-button type="text" size="small" @click="delRotation(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getHomeRotationInfoList"
      />
    </div>
  </div>
</template>

<script>
import {
  getPDFInfoList,
  getArticles,
  getUploadToken,
  getHomeRotationInfoList,
  addRotation,
  modifyRotation,
  delRotation,
  getActivityList
} from '../../request/http'

export default {
  data() {
    return {
      radioSelected: [],
      tabelList: [],
      imageUrl: '',
      title: '', //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: '', // 列表
      options: [
        {
          lebal: '资讯',
          status: 'ARTICLE'
        },
        {
          lebal: 'PDF',
          status: 'PDF'
        },

        {
          lebal: '外部链接',
          status: 'WEBURL'
        },
        {
          lebal: '活动',
          status: 'ACTIVITY'
        }
      ],
      total: 0, //总条数
      count: 0,
      qq: {
        page: 1,
        pageSize: 10
      },
      postData: {
        token: '',
        key: '' //上传的Logo
      },
      queryParams: {
        page: 1,
        pageSize: 10
      },
      formData: {
        originId: '',
        urlMsg: '',
        originType: '',
        logoId: '',
        title: ''
      },
      rules: {
        title: [
          { required: true, message: '标题名称不能为空', trigger: 'blur' }
        ],
        logoId: [{ required: true, message: 'logo不能为空', trigger: 'blur' }],
        publishUserName: [
          { required: true, message: '发布人名称不能为空', trigger: 'blur' }
        ],
        urlMsg: [
          { required: true, message: '外部链接不能为空', trigger: 'blur' }
        ],
        //  originId: [
        //   { required: true, message: "来源ID不能为空", trigger: "blur" },
        // ],
        originType: [
          { required: true, message: '来源类别不能为空', trigger: 'blur' }
        ],
        sort: [{ required: true, message: '排序不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getHomeRotationInfoList()
  },
  methods: {
    // 点击行选中,绑定数据
    // activityId
    handleSelectionChange(val, row) {
      //  console.log(row);
      let title = row.title
      // this.formData.originId = row.pdfId
      //   ? (this.formData.originId = row.pdfId)
      //   : (this.formData.originId = row.articleId)
      this.formData.originId = row.pdfId
        ? (this.formData.originId = row.pdfId)
        : row.activityId
        ? (this.formData.originId = row.activityId)
        : (this.formData.originId = row.articleId)

      this.formData.originId && this.$message.success('已选择' + title)
      console.log(this.formData.originId)
      this.$refs.singleTable.clearSelection()
      this.$refs.singleTable.toggleRowSelection(row, true)
    },

    async getPDFInfoList() {
      let { data, count } = await getPDFInfoList(this.qq)
      ;(this.tabelList = data), (this.count = count)
    },
    async getArticles() {
      let { data, count } = await getArticles(this.qq)
      ;(this.tabelList = data), (this.count = count)
    },

    async getActivityList() {
      const res = await getActivityList({
        page: 1,
        pageSize: 10
      })
      console.log(res, '****')
      this.tabelList = res.data
      this.count = res.count
    },

    enter(row) {
      this.$nextTick(function () {
        let title = row.title
        this.formData.originId = row.pdfId
          ? (this.formData.originId = row.pdfId)
          : (this.formData.originId = row.articleId)
        this.$refs.singleTable.clearSelection()
        this.$refs.singleTable.toggleRowSelection(row, true)
        this.formData.originId && this.$message.success('已选择' + title)
        console.log(this.formData.originId)
      })
    },
    selectChange(value) {
      this.formData.originType = value
      console.log(this.formData.originType)
      this.tabelList = []
      if (this.formData.originType == 'ARTICLE') {
        this.getArticles()
      } else if (this.formData.originType == 'PDF') {
        this.getPDFInfoList()
      } else if (this.formData.originType == 'ACTIVITY') {
        this.getActivityList()
      }
    },

    //获取列表
    async getHomeRotationInfoList() {
      let { data, count } = await getHomeRotationInfoList(this.queryParams)
      this.list = data
      this.total = count
      console.log(this.list)
      this.loading = false
    },

    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log('上传成功', res, file)
      this.imageUrl = 'http://file.innopinenut.com/' + res.key
      this.formData.logoId = res.key
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken()
      // console.log(data);
      if (data == '' || data == null) {
        this.message.error('上传图片时获取Token失败！')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },

    // 打开添加的窗口
    addSchoolInfo() {
      this.title = '添加轮播图'
      this.showDialog = true
    },
    // 打开编辑类别的窗口
    async updateSchoolInfo(val) {
      console.log(val)
      this.imageUrl = 'http://file.innopinenut.com/' + val.logoId
      this.title = '编辑轮播图'
      this.formData = {
        rotationId: val.rotationId,
        logoId: val.logoId,
        originType: val.originType,
        originId: val.originId,
        title: val.title,
        urlMsg: val.urlMsg,
        sort: val.sort
      }
      if (val.originType == 'pdf') {
        let { data, count } = await getPDFInfoList({ title: val.title })
        ;(this.tabelList = data), (this.count = count)
        this.showDialog = true
      } else if (val.originType == 'ARTICLE') {
        let { data, count } = await getArticles({ title: val.title })
        ;(this.tabelList = data), (this.count = count)
        this.showDialog = true
      } else if (val.originType == 'ACTIVITY') {
        let { data, count } = await getActivityList({ title: val.title })
        ;(this.tabelList = data), (this.count = count)
        this.showDialog = true
      }
      this.showDialog = true
    },

    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formData.rotationId) {
            this.formData.sort = Number(this.formData.sort)
            console.log(this.formData)
            return modifyRotation(this.formData)
          } else {
            console.log(this.formData)
            return addRotation(this.formData)
          }
        })
        .then((res) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else if (this.formData.rotationId) {
            this.$message.success('编辑成功')
          } else {
            this.$message.success('新增成功')
          }

          this.getHomeRotationInfoList()
          this.showDialog = false
        })
    },

    //弹窗取消函数
    btnCancel() {
      this.imageUrl = ''
      this.formData = {}
      this.$refs.perForm.resetFields()

      this.showDialog = false
    },
    //删除信息
    async delRotation(row) {
      try {
        await this.$confirm('确定要删除吗')
        await delRotation({ rotationId: row.rotationId })
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        )
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page
        this.getHomeRotationInfoList()

        this.$message.success('删除成功')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table__header .el-checkbox {
  display: none !important;
}
</style>